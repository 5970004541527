import { StyledCol, StyledRowOffset } from '@/shared/styles';
import styled from 'styled-components';

export const StyledImportanceCol = styled(StyledCol)`
  gap: 32px;
  flex: 1;
  min-width: 350px;

  h2 {
    align-self: start;
  }

  @media (min-width: 1536px) {
    flex: 0 0 40%;
  }
`;

export const StyledImportance = styled(StyledRowOffset)`
  flex-wrap: wrap;
  gap: 40px;

  @media (min-width: 601px) and (max-width: 1229px) {
    jusitfy-content: center;
  }
`;
