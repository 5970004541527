export enum ThemeMode {
  DARK_MODE = 'dark_mode',
  LIGHT_MODE = 'light_mode',
}

export type ThemeState = {
  isDarkMode: boolean;
  toggleTheme: () => void;
  setTheme: (theme: ThemeMode | undefined) => void;
  getTheme: () => undefined | ThemeMode;
};
