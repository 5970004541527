import { StyledCol, StyledPurpleText } from '@/shared/styles';
import React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { StyledHybridTitle, StyledLPText } from '../../../LandingPages.styles';
import { StyleNoBreakSpan } from '../../../MainLandingPage/Areas/Intro/Intro.styles';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { useTranslation } from 'react-i18next';

export const IntroContent: React.FC = () => {
  const { t } = useTranslation();
  const txt = `User1st is a leading provider of digital Web and mobile accessibility solutions for large
  organizations, offering comprehensive tools and services to ensure websites and applications are
  accessible to people with disabilities.`;
  const textSize = isMobile ? 24 : 32;
  return (
    <StyledCol $gap={32} $width={isMobile ? '100%' : '60%'}>
      <StyledCol $gap={16}>
        <StyledHybridTitle level={2} $align={isMobile ? 'center' : 'left'} $size={textSize}>
          <StyleNoBreakSpan>{t('landing_page.enterprise.intro.title.normal')}</StyleNoBreakSpan>
          <StyledPurpleText fontSize={`${textSize}px`}>
            {t('landing_page.enterprise.intro.title.gradient')}
          </StyledPurpleText>
        </StyledHybridTitle>
        <StyledLPText textAlign={isDesktop ? 'start' : 'center'} $width={isMobile ? '100%' : '85%'}>
          {t('landing_page.enterprise.intro.content')}
        </StyledLPText>
      </StyledCol>
      <DemoModal $btnText={t('landing_page.request_demo')} />
    </StyledCol>
  );
};
