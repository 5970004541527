import { COOKIES, RES_STATUS } from '@/shared/consts';
import { journeyParams, UserJourney, utmParams } from './UserJourney.consts';
import {
  getCookie,
  hasExceededCookieSize,
  isCookieAllowed,
  removeCookie,
  setCookie,
} from '@/services/cookieService';
import { CookieType } from '../AllowCookies/AllowCookies.consts';
import { useAxios } from '@/services/apiClient';
import { useEffect } from 'react';

export const useUserJourney = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const [{ response: trackGuestJourneyResponse }, postTrackGuestJourney] = useAxios(
    {
      method: 'post',
      url: '/user-journey/guest/track',
    },
    { manual: true }
  );

  const [{ response: trackUserJourneyResponse }, postTrackUserJourney] = useAxios(
    {
      method: 'post',
      url: '/user-journey/track',
    },
    { manual: true }
  );

  useEffect(() => {
    if (
      trackUserJourneyResponse?.status === RES_STATUS.CREATED ||
      trackGuestJourneyResponse?.status === RES_STATUS.CREATED
    ) {
      const journeyCookieArr = getCookie(COOKIES.JOURNEY);
      // remove only if there's more than one cookie so it won't be removed and added upon every page load
      if (journeyCookieArr?.length > 1) {
        removeCookie(COOKIES.JOURNEY);
      }
    }
  }, [trackGuestJourneyResponse, trackUserJourneyResponse]);

  const shouldHandleJourney = () => {
    return isCookieAllowed(CookieType.TARGETING) && urlParams.size > 0;
  };

  const handleUserJourney = () => {
    if (!shouldHandleJourney()) return null;

    const journeyCookieArr = getCookie(COOKIES.JOURNEY);
    const journeyWithUtm = getJourneyObj(utmParams);
    // if you have an existing cookie
    if (journeyCookieArr && journeyCookieArr.length) {
      const newJourneyArray = journeyWithUtm
        ? [...journeyCookieArr, journeyWithUtm]
        : journeyCookieArr;
      // check if exceeding cookie size, if so then send to backend and remove cookies
      if (hasExceededCookieSize(newJourneyArray)) {
        newJourneyArray.shift();
        postTrackGuestJourney({ data: { items: newJourneyArray } });
        removeCookie(COOKIES.JOURNEY);
      } else {
        setCookie(COOKIES.JOURNEY, newJourneyArray, CookieType.TARGETING);
      }
    } else {
      // if there's no existing cookie
      const journeyWithParams = getJourneyObj(journeyParams, journeyWithUtm);
      if (journeyWithParams) {
        // send first time to backend
        postTrackGuestJourney({ data: { items: [journeyWithParams] } });
        setCookie(COOKIES.JOURNEY, [journeyWithParams], CookieType.TARGETING);
      }
    }
  };

  const getJourneyObj = (params: string[], userJourney?: UserJourney | null) => {
    const currUserJourney: UserJourney = {};
    params.forEach((param) => {
      const cookieParamValue = urlParams.get(param);
      if (cookieParamValue) {
        currUserJourney[param] = cookieParamValue;
      }
    });
    const finalUserJourney = {
      ...currUserJourney,
      ...(userJourney ?? { created_at: new Date(Date.now()).toISOString() }),
    };

    return Object.keys(finalUserJourney).length > 1 ? finalUserJourney : null;
  };

  const getJourneyItems = () => {
    if (!shouldHandleJourney()) return [];
    const journeyCookieArr = getCookie(COOKIES.JOURNEY) ?? [];
    const newJourney = getJourneyObj([...utmParams, ...journeyParams]);
    return newJourney ? [...journeyCookieArr, newJourney] : journeyCookieArr;
  };

  // send user journey after login
  const sendUserJourney = () => {
    const items = getJourneyItems();
    items?.length && postTrackUserJourney({ data: { items } });
  };

  // send user journey before login
  const sendGuestJourney = () => {
    const items = getJourneyItems();
    items?.length && postTrackGuestJourney({ data: { items } });
  };

  return { handleUserJourney, sendGuestJourney, sendUserJourney };
};
