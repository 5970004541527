import { StyledAdjustableRow, StyledCol } from '@/shared/styles';
import {
  StyledLandingPageSection,
  StyledLPText,
  StyledWhiteTitle,
} from '../../../LandingPages.styles';
import { ElongatedInfoCard } from '../../../Common/Cards/ElongatedInfoCard/ElongatedInfoCard';
import { useTranslation } from 'react-i18next';
import { benefitsImages } from './Benefits.consts';
import { isMobile } from 'react-device-detect';

export const BenefitsOfComplience: React.FC = () => {
  const { t } = useTranslation();
  const padding = isMobile ? 80 : 140;
  return (
    <StyledLandingPageSection $paddingTop={padding} $paddingBottom={padding}>
      <StyledCol $gap={32}>
        <StyledWhiteTitle level={2} $align="center">
          {t('landing_page.eaa.benefits.title')}
        </StyledWhiteTitle>
        <StyledAdjustableRow $gap={20} $wrap="wrap">
          {benefitsImages.map((img, i) => (
            <ElongatedInfoCard
              key={`benefits-card${i}`}
              $title={t(`landing_page.eaa.benefits.card${i + 1}.title`)}
              $content={t(`landing_page.eaa.benefits.card${i + 1}.content`)}
              $img={img}
            />
          ))}
        </StyledAdjustableRow>
        <StyledLPText align="center">{t('landing_page.eaa.benefits.content')}</StyledLPText>
      </StyledCol>
    </StyledLandingPageSection>
  );
};
