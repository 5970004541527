import { FlexboxProps } from '@/shared/props';
import { StyledCol, StyledRow, StyledText, StyledTitle } from '@/shared/styles';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

export const StyledFooterCol = styled(StyledCol)<FlexboxProps>`
  gap: 24px;
  width: ${isMobile ? '135px' : 'fit-content'};
`;

export const StyledFooterTitle = styled(StyledTitle)`
  font-weight: 600 !important;
  font-size: 16px !important;
  word-break: normal;
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledFooterContent = styled(StyledText)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledFooterTrigger = styled.button`
  font-weight: 400 !important;
  font-size: 16px !important;
  background: none;
  border: none;
  color: ${(props) => props.theme.textColor};
  text-align: left;
  padding: 0;
`;

export const StyledDivider = styled.hr`
  border-top: 1px solid ${(props) => props.theme.textColor};
  width: 100%;
`;

export const StyledLinksSection = styled(StyledCol)`
  gap: 16px;

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  a:hover,
  button:hover {
    cursor: pointer !important;
  }
`;

export const StyledAdaptiveRow = styled(StyledRow)<FlexboxProps>`
  flex-wrap: wrap;
  width: ${(props) => props.$width ?? 'fit-content'};
  ${isDesktop && `justify-content: space-between;`}
  gap: ${isTablet ? '9' : isMobile ? `60` : `32`}px;
  flex: 1 1 auto;
`;

export const StyledMobileIcons = styled.div`
  display: flex;
  flex-direction: ${isTablet ? 'column' : 'row'};
  gap: 16px;
`;
