import { StyledCol, StyledPurpleText, StyledPurpleTitle } from '@/shared/styles';
import { StyledHybridTitle, StyledLPText } from '../../../LandingPages.styles';
import { isMobile } from 'react-device-detect';
import { StyleNoBreakSpan } from './Intro.styles';
import { useTranslation } from 'react-i18next';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={32} $width={isMobile ? '100%' : '55%'}>
      <StyledCol $gap={8}>
        <StyledPurpleTitle $size={20} $weight={600} $align="left">
          {t('landing_page.intro.title1.welcome')}:{isMobile ? <br /> : <></>}{' '}
          {t('landing_page.intro.title1.your_partner')}
        </StyledPurpleTitle>
        <StyledHybridTitle level={2} $break={true} $size={44}>
          <StyleNoBreakSpan>{t('landing_page.intro.title2.empowering')}</StyleNoBreakSpan>
          <div>
            <StyleNoBreakSpan>{t('with')} </StyleNoBreakSpan>
            <StyledPurpleText fontSize="44px">
              {t('landing_page.intro.title2.in_code')}
            </StyledPurpleText>
          </div>
        </StyledHybridTitle>
        <StyledLPText>{t('landing_page.intro.content')}</StyledLPText>
      </StyledCol>
      <DemoModal $btnText={t('landing_page.request_demo')} />
    </StyledCol>
  );
};
