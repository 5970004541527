import { StyledCol, StyledTitle } from '@/shared/styles';
import { StyledLPText } from '../../../LandingPages.styles';
import { StyledBorderedCard } from '../Cards.styles';
import { InfoCardProps } from '../Cards.consts';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';

export const BorderedInfoCard: React.FC<InfoCardProps> = ({
  $title,
  $content,
  $img,
}: InfoCardProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <StyledBorderedCard>
        <StyledCol $gap={16} $width={isMobileOnly ? '100%' : '50%'}>
          <StyledTitle level={2} $align={isMobile ? 'center' : 'left'}>
            {$title}
          </StyledTitle>
          <StyledLPText textAlign={isMobileOnly ? 'center' : 'left'}>{$content}</StyledLPText>
        </StyledCol>
        {$img}
      </StyledBorderedCard>
    </ThemeProvider>
  );
};
