import { USER_TYPE } from '@/shared/consts';
import { StyledSyntaxHighlighter } from '@/shared/styles';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { useUserStore } from '@/stores/UserStore';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as highlighterStyles from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getTextWithoutComments } from '../../Client/Projects/Projects.utils';
import { ClipboardSyntaxHighlighterProps } from './ClipboardSyntaxHighlighter.consts';
import {
  StyledClipboardContent,
  StyledClipboardLayout,
  StyledCopyButton,
} from './ClipboardSyntaxHighlighter.styles';
import { EditIcons } from './EditIcons';

export const ClipboardSyntaxHighlighter: React.FC<ClipboardSyntaxHighlighterProps> = (
  props: ClipboardSyntaxHighlighterProps
) => {
  const docs = useDocsStore();
  const userType = useUserStore((state) => state.type);
  const [isCopied, setisCopied] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isIconsVisible, setIsIconsVisible] = useState(false);
  const [editableContent, setContent] = useState<string>(props.content);
  const isDarkMode = useThemeStore((state) => state.isDarkMode);

  const { t } = useTranslation();

  useEffect(() => {
    if (props) setContent(props.content);
  }, [props]);

  const copyText = () => {
    const text = editableContent.toString();
    const textToCopy = props.copywithoutcomments ? getTextWithoutComments(text) : text;
    navigator.clipboard.writeText(textToCopy);
    setTimeout(() => {
      setisCopied(false);
    }, 1500);
    setisCopied(true);
  };

  return (
    <StyledClipboardLayout>
      <StyledClipboardContent
        onMouseEnter={() => setIsIconsVisible(true)}
        onMouseLeave={() => setIsIconsVisible(false)}
      >
        {userType === USER_TYPE.ADMIN && (
          <EditIcons
            objkeys={props.objkeys}
            originalContent={props.content}
            editableContent={editableContent}
            setContent={setContent as Dispatch<SetStateAction<string | string[]>>}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isVisible={isIconsVisible}
            index={props.index}
          />
        )}
        <StyledSyntaxHighlighter
          language={docs.docsLang}
          style={isDarkMode ? highlighterStyles.oneDark : highlighterStyles.oneLight}
          {...props}
        >
          {isEditMode ? editableContent : props.content}
        </StyledSyntaxHighlighter>
      </StyledClipboardContent>
      <StyledCopyButton role="status" aria-live="assertive" isCopied={isCopied} onClick={copyText}>
        {isCopied ? t('copied') : t('copy')}
      </StyledCopyButton>
    </StyledClipboardLayout>
  );
};
