import React, { useEffect, useState } from 'react';
import { StyledModal } from '../Modals.styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';
import { StyledCookieContent, StyledCookieLink } from './CookieModal.styles';
import { StyledCol, StyledSeparator, StyledTitle } from '@/shared/styles';
import { CookiesModalProps } from './CookieModal.consts';
import { v4 as uuidv4 } from 'uuid';
import { AcceptCookiesActions } from './AcceptCookiesAction/AcceptCookiesAction';

export const CookiesModal: React.FC<CookiesModalProps> = ({ setBannerOpen }: CookiesModalProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();
  const [modalKey, setModalKey] = useState(uuidv4());

  useEffect(() => {
    if (open) {
      setModalKey(uuidv4());
    }
  }, [open]);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setBannerOpen(false);
      setConfirmLoading(false);
      setOpen(false);
    }, 1000);
  };

  return (
    <U1Dialog name={ComponentName.COOKIES_DIALOG}>
      <StyledCookieLink
        onClick={showModal}
        data-a11y-dialog="trigger"
        data-a11y-ref={'cookies-modal'}
      >
        {t('cookie_banner.change_settings')}
        {' >'}
      </StyledCookieLink>
      <StyledModal
        key={modalKey}
        data-a11y-ref="cookies-modal"
        $width={700}
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
      >
        <StyledCol $gap={32}>
          <StyledCol $gap={12}>
            <StyledTitle level={2}>User1st</StyledTitle>
            <StyledSeparator />
            <StyledTitle level={3}>
              {t('cookie_banner.settings_modal.privacy_preference.title')}
            </StyledTitle>
            <StyledCookieContent>
              {t('cookie_banner.settings_modal.privacy_preference.content')}
            </StyledCookieContent>
          </StyledCol>
          <StyledCol $gap={32}>
            <StyledTitle level={3}>
              {t('cookie_banner.settings_modal.manage_consent.title')}
            </StyledTitle>
            <AcceptCookiesActions handleOk={handleOk} />
          </StyledCol>
        </StyledCol>
      </StyledModal>
    </U1Dialog>
  );
};
