import { StyledOrangeGradTitle } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { LogosGrid } from '@/Views/BeforeLogin/LandingPages/Common/Trusted/LogosGrid';
import { useTranslation } from 'react-i18next';
import {
  StyledMainSectionsText,
  StyledSectionContainer,
  StyledTopDescription,
} from '../../MainPage.styles';

export const U1TrustedBy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledSectionContainer>
      <StyledTopDescription>
        <StyledOrangeGradTitle level={2}>{t('homepage.trusted.title')}</StyledOrangeGradTitle>
        <StyledMainSectionsText>{t('homepage.trusted.content')}</StyledMainSectionsText>
      </StyledTopDescription>
      <LogosGrid />
    </StyledSectionContainer>
  );
};
