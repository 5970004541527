import { StyledAdjustableCol, StyledCol } from '@/shared/styles';
import { StyledBanner, StyledText, StyledTextSection } from './TextAndBanner.styles';
import { TextAndBannerProps } from './TextAndBanner.consts';
import React, { ReactElement } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';

export const TextAndBanner: React.FC<TextAndBannerProps> = ({
  title,
  leftText,
  rightText,
  bannerImg,
  imgAlt,
}: TextAndBannerProps) => {
  return (
    <StyledCol $gap={isDesktop ? 16 : 32}>
      {React.cloneElement(title as ReactElement, {
        level: 2,
      })}
      <StyledAdjustableCol
        $gap={32}
        $align="start"
        $wrap={isMobileOnly ? 'wrap' : 'nowrap'}
        $justify="center"
      >
        <StyledText>
          <StyledTextSection>{leftText}</StyledTextSection>
          <StyledTextSection>{rightText}</StyledTextSection>
        </StyledText>
        <StyledBanner src={bannerImg} alt={imgAlt ?? ''} />
      </StyledAdjustableCol>
    </StyledCol>
  );
};
