import { StyledAdjustableRow, StyledPurpleTitle } from '@/shared/styles';
import { StyledLandingPageSection, StyledLPText } from '../../../LandingPages.styles';
import { Image } from '@/shared/components/Image/Image';
import u1ToolsetImg from '@/assets/images/landingPage/eaa/u1Toolset.png';
import { useTranslation } from 'react-i18next';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { StyledTextContainer } from './U1Toolset.styles';

export const U1Toolset: React.FC = () => {
  const { t } = useTranslation();
  const textAlign = isMobileOnly ? 'center' : 'left';
  return (
    <StyledLandingPageSection>
      <StyledAdjustableRow
        $gap={40}
        $padding={isMobile ? '80px 0px' : '140px 0px'}
        $wrap="wrap"
        $justify="center"
      >
        <StyledTextContainer>
          <StyledPurpleTitle $align={textAlign} $noBreak={true}>
            {t('landing_page.eaa.toolset.title')}
          </StyledPurpleTitle>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.eaa.toolset.content1')}
          </StyledLPText>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.eaa.toolset.content2')}
          </StyledLPText>
        </StyledTextContainer>
        {(isDesktop || isTablet) && <Image $src={u1ToolsetImg} $height="100%" />}
      </StyledAdjustableRow>
    </StyledLandingPageSection>
  );
};
