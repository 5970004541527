import { isMobile } from 'react-device-detect';
import { OurFeatures } from '../../../Common/Features/Features';
import { eaaBgGradients } from '../../../LandingPages.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const OurFeaturesEAA: React.FC = () => {
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);
  return (
    <OurFeatures
      $titleAlign="center"
      $bgColor={isDarkTheme ? eaaBgGradients.features : undefined}
      $paddingTop={isMobile ? 80 : 140}
      $paddingRight={0}
      $paddingLeft={0}
    />
  );
};
