import { useTranslation } from 'react-i18next';
import { Trusted } from '../../../Common/Trusted/Trusted';
import { eaaBgGradients } from '../../../LandingPages.consts';
import { darkTheme } from '@/shared/themes/darkTheme';
import { ThemeProvider } from 'styled-components';
import { THEME_ICONS } from '@/shared/consts';

export const TrustedEnterprises: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={darkTheme}>
      <Trusted
        $paddingBottom={80}
        $paddingTop={80}
        $bgColor={eaaBgGradients.trusted}
        $title={t('landing_page.eaa.trusted.title')}
        $content={t('landing_page.eaa.trusted.content')}
        $iconsColor={THEME_ICONS.WHITE}
      />
    </ThemeProvider>
  );
};
