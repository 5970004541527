import introImgMobile from '@/assets/images/landingPage/introImgMobile.png';
import { Image } from '@/shared/components/Image/Image';
import { Intro } from './Intro';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { StyledMobileIntro } from './Intro.styles';

export const MobileIntro: React.FC = () => {
  return (
    <StyledLandingPageSection>
      <StyledMobileIntro>
        <Intro />
        <Image $src={introImgMobile} $margin="0px -16px 0px 0px" $position="relative" />
      </StyledMobileIntro>
    </StyledLandingPageSection>
  );
};
