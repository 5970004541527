import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { StyledBanner } from '../../../Common/TextAndBanner/TextAndBanner.styles';
import { StyledLandingPageSection } from '../../../LandingPages.styles';

export const StyledEnterpriseSuitability = styled(StyledLandingPageSection)`
  color: ${(props) => props.theme.textColor};

  h2 {
    align-self: ${() => (isMobile ? 'center' : 'start')} !important;
  }

  && ${StyledBanner} {
    border: 2px solid ${(props) => props.theme.overlayTextBorder};
    border-radius: 40px;
  }
`;
