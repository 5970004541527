import longTermImg from '@/assets/images/landingPage/enterprise/longTermImg.png';
import { Image } from '@/shared/components/Image/Image';
import { StyledAdjustableReversedRow, StyledCol, StyledPurpleTitle } from '@/shared/styles';
import React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { StyledLandingPageSection, StyledLPText } from '../../../LandingPages.styles';
import { useTranslation } from 'react-i18next';

export const LongTerm: React.FC = () => {
  const textAlign = isDesktop ? 'start' : 'center';
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <StyledAdjustableReversedRow $gap={40}>
        <StyledCol $gap={16} $justify="center">
          <StyledPurpleTitle $align={isMobile ? 'center' : 'left'}>
            {t('landing_page.enterprise.long_term.title')}
          </StyledPurpleTitle>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.enterprise.long_term.content1')}
          </StyledLPText>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.enterprise.long_term.content2')}
          </StyledLPText>
        </StyledCol>
        <Image $src={longTermImg} $width="100%" />
      </StyledAdjustableReversedRow>
    </StyledLandingPageSection>
  );
};
