import React from 'react';
import { SolutionsCardsProps } from './ModernCompatibility.consts';
import { StyledSolutionsCard, StyledSolutionsCardText } from './ModernCompatibility.styles';

export const SolutionsCards: React.FC<SolutionsCardsProps> = ({ text }) => {
  return (
    <StyledSolutionsCard>
      <StyledSolutionsCardText>{text}</StyledSolutionsCardText>
    </StyledSolutionsCard>
  );
};
