import { StyledCol } from '@/shared/styles';
import {
  StyledAdaptiveRow,
  StyledFooterCol,
  StyledFooterContent,
  StyledFooterTitle,
  StyledFooterTrigger,
  StyledLinksSection,
  StyledMobileIcons,
} from './Footer.styles';
import {
  certifications,
  compliences,
  getAboutSections,
  mobilePlatforms,
  mobilePlatformsImages,
  platforms,
} from './Footer.consts';
import { Icon } from '@/shared/components/Icon';
import { StyledLinkNoStyle } from '@/shared/components/Link/Links.styles';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import iso from '@/assets/images/landingPage/footer/iso.svg';
import { useTranslation } from 'react-i18next';

export const FooterSections: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledAdaptiveRow>
      <StyledFooterCol>
        <StyledFooterTitle level={2}>{t('landing_page.footer.complience.title')}</StyledFooterTitle>
        <StyledCol $gap={16}>
          {compliences.map((comp, i) => (
            <StyledFooterContent key={`${comp}${i + 1}`}>{comp}</StyledFooterContent>
          ))}
        </StyledCol>
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle level={2}>
          {t('landing_page.footer.certifications.title')}
        </StyledFooterTitle>
        <StyledCol $gap={16}>
          {certifications.map((cert, i) => (
            <StyledFooterContent key={`${cert}${i + 1}`}>{cert}</StyledFooterContent>
          ))}
        </StyledCol>
        <Icon $src={iso} $maxWidth={190} $alt="iso 27018:2019, iso 27001, iso 2707:2015, W3C" />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle level={2}>
          {t('landing_page.footer.web_platforms.title')}
        </StyledFooterTitle>
        <StyledCol $gap={16}>
          {platforms.map((plat, i) => (
            <StyledFooterContent key={`${plat}${i + 1}`}>{plat}</StyledFooterContent>
          ))}
        </StyledCol>
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle level={2}>
          {t('landing_page.footer.mobile_platforms.title')}
        </StyledFooterTitle>
        <StyledCol $gap={16}>
          {mobilePlatforms.map((mp, i) => (
            <StyledFooterContent key={`${mp}${i + 1}`}>{mp}</StyledFooterContent>
          ))}
        </StyledCol>
        <StyledMobileIcons>
          {mobilePlatformsImages.map((img, i) => (
            <Icon key={`platform${i}`} $src={img.src} $alt={img.alt} $size={32} />
          ))}
        </StyledMobileIcons>
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle level={2}>{t('landing_page.footer.about.title')}</StyledFooterTitle>
        <StyledLinksSection>
          {getAboutSections().map((abt, i) =>
            abt.route ? (
              <StyledLinkNoStyle
                aria-label={abt.text}
                to={abt.route}
                key={abt.text}
                target="_blank"
                rel="noreferrer"
              >
                <StyledFooterContent key={`${abt.text}${i + 1}`}>{abt.text}</StyledFooterContent>
              </StyledLinkNoStyle>
            ) : (
              <ContactUsModal
                key={`${abt.text}${i + 1}`}
                refName={`contact-us-footer${i + 1}`}
                trigger={
                  <StyledFooterTrigger
                    data-a11y-dialog="trigger"
                    data-a11y-ref={`contact-us-footer${i + 1}`}
                  >
                    {abt.text}
                  </StyledFooterTrigger>
                }
              />
            )
          )}
        </StyledLinksSection>
      </StyledFooterCol>
    </StyledAdaptiveRow>
  );
};
