import { StyledCol, StyledPurpleTitle, StyledRow } from '@/shared/styles';
import React from 'react';
import { StyledLPText } from '../../../LandingPages.styles';
import { SolutionsCards } from './SolutionsCards';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const CompatibilityDescription: React.FC = () => {
  const textAlign = isDesktop ? 'start' : 'center';
  const { t } = useTranslation();
  return (
    <StyledCol $gap={16}>
      <StyledPurpleTitle level={2} $align={isMobile ? 'center' : 'left'}>
        {t('landing_page.enterprise.compatibility.title')}
      </StyledPurpleTitle>
      <StyledLPText textAlign={textAlign}>
        {t('landing_page.enterprise.compatibility.content1')}
      </StyledLPText>
      <StyledLPText textAlign={textAlign}>
        {t('landing_page.enterprise.compatibility.content2')}
      </StyledLPText>
      <StyledRow $gap={24} $align="center">
        <SolutionsCards text="u1" />
        <SolutionsCards text="m1" />
        <SolutionsCards text="cl" />
        <SolutionsCards text="CI/CD" />
      </StyledRow>
    </StyledCol>
  );
};
