import inclusion from '@/assets/images/landingPage/ourFeatures/inclusion.svg';
import accessibility from '@/assets/images/landingPage/ourFeatures/accessibility.svg';
import supervision from '@/assets/images/landingPage/ourFeatures/supervision.svg';
import mobile from '@/assets/images/landingPage/ourFeatures/mobile.svg';
import costEffective from '@/assets/images/landingPage/ourFeatures/costEffective.svg';
import monitoring from '@/assets/images/landingPage/ourFeatures/monitoring.svg';
import { ReactElement } from 'react';

export const featuresImages = [
  inclusion,
  accessibility,
  supervision,
  mobile,
  costEffective,
  monitoring,
];

export type OurFeaturesProps = {
  $title: ReactElement;
};
