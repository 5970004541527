import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';
import { isCookieAllowed } from './cookieService';

export const setLocalStorage = (
  key: string,
  value: object | string,
  cookieType: CookieType
): void => {
  if (isCookieAllowed(cookieType)) localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  const storageItem = localStorage.getItem(key);
  return storageItem ? JSON.parse(storageItem) : null;
};

export const removeItemFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const setSessionStorage = (
  key: string,
  value: object | string,
  cookieType: CookieType
): void => {
  if (isCookieAllowed(cookieType)) sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorage = (key: string) => {
  const storageItem = sessionStorage.getItem(key);
  return storageItem ? JSON.parse(storageItem) : null;
};

export const removeItemFromSessionStorage = (key: string): void => {
  sessionStorage.removeItem(key);
};
