import { StyledButtonBanner, StyledGetStartedText } from './ButtonBanner.styles';
import { ButtonBannerProps } from './ButtonBanner.consts';
import { StyledCol } from '@/shared/styles';
import React from 'react';

export const ButtonBanner: React.FC<ButtonBannerProps> = ({
  $title1,
  $title2,
  $content,
  $button,
  $filled,
}: ButtonBannerProps) => {
  return (
    <StyledButtonBanner $gap={24} $filled={$filled} className="btn-banner">
      <StyledCol $gap={16} $align="center">
        {React.cloneElement($title1, { level: 2 })}
        {$title2 && React.cloneElement($title2, { level: 3 })}
        <StyledGetStartedText>{$content}</StyledGetStartedText>
      </StyledCol>
      {$button}
    </StyledButtonBanner>
  );
};
