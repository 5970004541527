import { TextAndBanner } from '../../../Common/TextAndBanner/TextAndBanner';
import { StyledLandingPageSection, StyledWhiteTitle } from '../../../LandingPages.styles';
import introductionImg from '@/assets/images/landingPage/eaa/eaaIntroduction.png';
import introductionMobile from '@/assets/images/landingPage/eaa/introductionMobile.png';
import { useTranslation } from 'react-i18next';
import { isDesktop, isMobile } from 'react-device-detect';
import { eaaBgGradients } from '../../../LandingPages.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const Introduction: React.FC = () => {
  const { t } = useTranslation();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);

  return (
    <StyledLandingPageSection
      $bgColor={isDarkTheme ? eaaBgGradients.introduction : undefined}
      $paddingTop={80}
    >
      <TextAndBanner
        title={
          <StyledWhiteTitle $size={isMobile ? 24 : 32} $flex={isDesktop ? 'start' : 'center'}>
            {t('landing_page.eaa.introduction.title')}
          </StyledWhiteTitle>
        }
        bannerImg={isMobile ? introductionMobile : introductionImg}
        leftText={t('landing_page.eaa.introduction.leftText')}
        rightText={t('landing_page.eaa.introduction.rightText')}
      />
    </StyledLandingPageSection>
  );
};
