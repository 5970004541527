import React, { useEffect, useState } from 'react';
import { StyledFooterLink } from '@/Views/BeforeLogin/MainPage/Footer/Footer.styles';
import { PAGES } from '@/shared/consts';
import { StyledCookieBanner } from './Banner.styles';
import { useTranslation } from 'react-i18next';
import { CookiesModal } from '../Modals/CookieModal/CookieModal';
import { useAllowCookies } from '@/hooks/AllowCookies/useAllowCookies';
import { useUserJourney } from '@/hooks/UserJourney/useUserJourney';

export const CookieBanner: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { acceptAllCookies, isCookieSaved } = useAllowCookies();
  const { handleUserJourney } = useUserJourney();

  useEffect(() => {
    if (!open) handleUserJourney();
  }, [open]);

  useEffect(() => {
    setOpen(!isCookieSaved);
  }, [isCookieSaved]);

  const onClose = () => {
    if (!isCookieSaved) acceptAllCookies();
    setOpen(false);
  };

  return (
    <StyledCookieBanner
      title={t('cookie_banner.main_text')}
      placement="bottom"
      onClose={onClose}
      open={open}
      height={150}
    >
      <StyledFooterLink to={PAGES.SHARED.PRIVACY_POLICY} target="_blank" rel="noreferrer">
        {t('footer.privacy_policy')}
        {' >'}
      </StyledFooterLink>
      <CookiesModal setBannerOpen={setOpen} />
    </StyledCookieBanner>
  );
};
