import { StyledAdjustableRow } from '@/shared/styles';
import { isDesktop, isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledBanner = styled.img`
  border-radius: 24px;
  width: 100%;
`;

export const StyledTextSection = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-weight: 400;
  ${isDesktop && `width: 50%;`}
  ${isMobile && `text-align: center;`}
`;

export const StyledText = styled(StyledAdjustableRow)`
  gap: 20px;
  justify-content: space-between;
`;
