import React from 'react';
import { Trusted } from '../Common/Trusted/Trusted';
import { OurSolution } from './Areas/OurSolution/OurSolution';
import { StyledCol } from '@/shared/styles';
import { Institutions } from './Areas/Institutions/Institutions';
import { isMobile } from 'react-device-detect';
import { MobileIntro } from './Areas/Intro/MobileIntro';
import { DesktopIntro } from './Areas/Intro/DesktopInto';
import { GetStarted } from './Areas/GetStarted/GetStarted';
import { OurFeatures } from '../Common/Features/Features';
import { Importance } from './Areas/Importance/Importance';
import { LandingPageTemplate } from '../LandingPageTemplate';
import { useTranslation } from 'react-i18next';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import circleGradient from '@/assets/images/landingPage/circleGradient.svg';

export const MainLandingPage: React.FC = () => {
  const { t } = useTranslation();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);

  return (
    <LandingPageTemplate>
      <StyledCol $gap={isMobile ? 80 : 240}>
        {isMobile ? <MobileIntro /> : <DesktopIntro />}
        <Trusted $title={t('landing_page.trusted.title')} />
      </StyledCol>
      <OurSolution />
      <Institutions />
      <OurFeatures $bgImg={isDarkTheme ? circleGradient : undefined} />
      <Importance />
      <GetStarted />
    </LandingPageTemplate>
  );
};
