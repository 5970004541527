import { ImageProps } from './Image.consts';
import { StyledImage } from './Image.styles';

export const Image: React.FC<ImageProps> = ({
  $src,
  $alt,
  $position,
  $width,
  $height,
  $margin,
  $flex,
}: ImageProps) => {
  return (
    <StyledImage
      loading="lazy"
      alt={$alt ?? ''}
      src={$src}
      $position={$position}
      $width={$width}
      $margin={$margin}
      $height={$height}
      $flex={$flex}
    />
  );
};
