import { StyledCol, StyledPurpleTitle, StyledText } from '@/shared/styles';
import { StyledLeftCol, StyledOurSolution, StyledRightColImg } from './OurSolution.styles';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import prodsPreview from '@/assets/images/landingPage/prodsPreview.png';
import { StyledAdaptiveRow } from '../../../Common/Footer/Footer.styles';

export const OurSolution: React.FC = () => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());
  return (
    <StyledLandingPageSection>
      <StyledOurSolution $gap={40}>
        <StyledPurpleTitle level={2}>{t('landing_page.our_solution.title')}</StyledPurpleTitle>
        <StyledAdaptiveRow $justify={isRtl ? 'flex-end' : 'space-between'} $width="100%">
          <StyledLeftCol>
            {[...Array(3)].map((_, i) => (
              <StyledCol key={`solution${i}`} $gap={16}>
                <StyledPurpleTitle
                  level={3}
                  $size={20}
                  $align={isRtl ? 'right' : 'left'}
                  $width={isRtl ? 'fit-content' : '100%'}
                >
                  {t(`landing_page.our_solution.block${i + 1}.title`)}:
                </StyledPurpleTitle>
                <StyledText fontSize={'18px'} fontWeight={400}>
                  {t(`landing_page.our_solution.block${i + 1}.content`)}
                </StyledText>
              </StyledCol>
            ))}
          </StyledLeftCol>
          <StyledRightColImg src={prodsPreview} />
        </StyledAdaptiveRow>
      </StyledOurSolution>
    </StyledLandingPageSection>
  );
};
