import { CookieType } from '../AllowCookies/AllowCookies.consts';
import {
  getSessionStorage,
  removeItemFromSessionStorage,
  setSessionStorage,
} from '@/services/storageService';

export const useSessionStorage = () => {
  const setStorage = (key: string, value: object | string, cookieType: CookieType) => {
    setSessionStorage(key, value, cookieType);
  };

  const getStorage = (key: string) => {
    return getSessionStorage(key);
  };

  const removeStorage = (key: string) => {
    removeItemFromSessionStorage(key);
  };

  return { setStorage, getStorage, removeStorage };
};
