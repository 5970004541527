import React from 'react';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { usePaymentStore } from '@/stores/PaymentStore';
import { PaymentForm } from './PaymentForm';
import { StyledStripePayment } from './Stripe.styles';
import { PaymentFormProps } from './Stripe.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_PK}`);

export const StripeCardPayment: React.FC<PaymentFormProps> = ({
  paymentStatus,
  children,
}: PaymentFormProps) => {
  const clientSecret = usePaymentStore((state) => state.clientSecret);
  const isDarkMode = useThemeStore((state) => state.isDarkMode);

  const appearance: Appearance = {
    theme: isDarkMode ? 'night' : 'stripe',
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <StyledStripePayment className="Stripe-App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm paymentStatus={paymentStatus}>{children}</PaymentForm>
        </Elements>
      )}
    </StyledStripePayment>
  );
};
