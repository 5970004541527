import { SearchIcon } from '@/assets/images/icons';
import { Icon } from '../Icon';
import { StyledSearch } from './Search.styles';
import { SearchProps } from './Search.consts';
import { U1Search } from '@u1/react-a11y-hooks';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { useTranslation } from 'react-i18next';

export const SearchInput: React.FC<SearchProps> = ({
  onChange,
  placeholder,
  label,
  testId,
  onSearch,
  $width,
}: SearchProps) => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { t } = useTranslation();
  return (
    <U1Search>
      <StyledSearch
        data-testid={testId}
        onChange={onChange}
        onSearch={onSearch}
        placeholder={placeholder ?? t('projects.docs.tree.search')}
        aria-label={label}
        enterButton={
          <Icon
            $src={SearchIcon}
            $size={24}
            $alt={t('projects.docs.tree.search')}
            $invert={!isDarkMode}
          />
        }
        $width={$width}
      />
    </U1Search>
  );
};
