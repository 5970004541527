export interface AllowedCookies {
  necessary: boolean;
  performance: boolean;
  functional: boolean;
  targeting: boolean;
  social: boolean;
}

export enum CookieType {
  NECESSARY = 'necessary',
  PERFORMANCE = 'performance',
  FUNCTIONAL = 'functional',
  TARGETING = 'targeting',
  SOCIAL = 'social',
}
