export const supportedFrameworks = [
  {
    alt: 'React',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/reactjs.svg`,
  },
  {
    alt: 'Angular',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/ngMainPage.svg`,
  },
  {
    alt: 'Vue JS',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/vueMainPage.svg`,
  },
  {
    alt: 'Vanilla JS',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/java-script.svg`,
  },
  {
    alt: 'Android',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/android.svg`,
  },
  {
    alt: 'iOS',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/ios.svg`,
  },
  {
    alt: 'Flutter',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/flutter.svg`,
  },
  {
    alt: 'React Native',
    src: `${import.meta.env.VITE_BASE_IMAGES_URL}/icons/react-native.svg`,
  },
];
