import styled from 'styled-components';
import { ImageProps } from './Image.consts';

export const StyledImage = styled.img<ImageProps>`
  ${(props) => props.$position && `position: ${props.$position}`};
  ${(props) => props.$height && `height: ${props.$height}`};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.$flex && `flex: ${props.$flex}`};
`;
