import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';
import { ImageContainerProps } from './TextInsideImage.consts';

export const StyledImageContainer = styled(StyledRow)<ImageContainerProps>`
  background-image: url(${(props) => props.$imgSrc});
  background-size: 100% 100%;
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
  height: 436px;
  position: relative;
  border-radius: 16px;
  width: ${(props) => props.$width ?? `100%`};
`;

export const StyledOverlayTextContainer = styled.div`
  color: ${(props) => props.theme.sameColorText} !important;
  background: ${(props) => props.theme.overlayTextBG};
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
  border-radius: 16px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 0px;
  font-size: '14px';
  font-weight: 400;
`;
