import { TextProps } from '@/shared/props';
import { StyledCol, StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyleNoBreakSpan = styled.span<TextProps>`
  overflow-wrap: break-word;
  font-size: 44px;
`;

export const StyledDesktopIntro = styled(StyledRow)`
  img {
    width: 35rem;
  }

  padding-top: 100px;
`;

export const StyledMobileIntro = styled(StyledCol)`
  gap: 40px;
  padding-top: 40px;
`;

export const StyledIntroImg = styled.img`
  width: 100%
  height: auto;
  margin: -100px -100px 0px 0px;
`;
