import { create } from 'zustand';
import { ThemeMode, ThemeState } from './ThemeStore.consts';
import { getCookie, setCookie } from '@/services/cookieService';
import { COOKIES } from '@/shared/consts';
import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';

export const useThemeStore = create<ThemeState>((set, get) => ({
  isDarkMode: true,
  toggleTheme: () => {
    const isDarkMode = get().isDarkMode;
    setCookie(
      COOKIES.THEME,
      isDarkMode ? ThemeMode.LIGHT_MODE : ThemeMode.DARK_MODE,
      CookieType.FUNCTIONAL
    );
    set((state) => ({
      ...state,
      isDarkMode: !isDarkMode,
    }));
  },
  setTheme: (theme: ThemeMode | undefined) => {
    const isDarkMode = !theme || theme === ThemeMode.DARK_MODE;
    set((state) => ({ ...state, isDarkMode }));
  },
  getTheme: () => {
    return getCookie(COOKIES.THEME);
  },
}));
