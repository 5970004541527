import { isMobile } from 'react-device-detect';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { StyledHybridTitle, StyledLandingPageSection } from '../../../LandingPages.styles';
import { useTranslation } from 'react-i18next';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import {
  StyledGetStartedPurpleText,
  StyledGetStartedPurpleTitle,
  StyledGetStartedWhiteText,
} from './GetStarted.styles';

export const GetStarted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <ButtonBanner
        $title1={
          <StyledGetStartedPurpleTitle level={2} $size={16}>
            {t('landing_page.get_started.title1')}
          </StyledGetStartedPurpleTitle>
        }
        $title2={
          <StyledHybridTitle level={3} $align="center" $width={isMobile ? '100%' : '85%'}>
            <StyledGetStartedWhiteText>
              {t('landing_page.get_started.title2.solutions')}{' '}
            </StyledGetStartedWhiteText>
            <StyledGetStartedPurpleText>
              {t('landing_page.get_started.title2.make_accessible')}
            </StyledGetStartedPurpleText>
          </StyledHybridTitle>
        }
        $content={t('landing_page.get_started.content')}
        $button={
          <DemoModal
            $btnText={isMobile ? t('landing_page.request_demo') : t('landing_page.schedule_demo')}
            $refName="contact-us-get-started"
          />
        }
      />
    </StyledLandingPageSection>
  );
};
