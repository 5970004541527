import { StyledAdjustableRow } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

const gradientStyle = css`
  background: ${(props) => props.theme.linkGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 16px;
`;

export const StyledCookieLink = styled.a`
  ${gradientStyle}
`;

export const StyledPinkGradText = styled.span`
  ${gradientStyle}
`;

export const StyledCookieContent = styled.div`
  font-family: 'U1Poppins';
  font-weight: 300;
  font-size: 16px;
  color: ${(props) => props.theme.secondaryTextColor};
`;

export const StyledButtonsContainer = styled(StyledAdjustableRow)`
  justify-content: flex-end;
  gap: 12px;
`;
