import { StyledCol, StyledPurpleTitle } from '@/shared/styles';
import { LogoSlider } from './LogoSlider';
import { StyledLandingPageSection, StyledLPText } from '../../LandingPages.styles';
import { TrustedSliderProps } from './Trusted.consts';
import { LogosGrid } from './LogosGrid';

export const Trusted: React.FC<TrustedSliderProps> = (props: TrustedSliderProps) => {
  const { $title, $content, $iconsColor, $isSlider = true, ...restOfProps } = props;
  return (
    <StyledLandingPageSection {...restOfProps}>
      <StyledCol $gap={16}>
        <StyledPurpleTitle level={2} $align="center" $size={32}>
          {$title}
        </StyledPurpleTitle>
        {$content && (
          <StyledLPText align="center" fontSize="18px">
            {$content}
          </StyledLPText>
        )}
        {$isSlider ? <LogoSlider $iconsColor={$iconsColor} /> : <LogosGrid />}
      </StyledCol>
    </StyledLandingPageSection>
  );
};
