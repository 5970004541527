import enterpriseSuitabilityImg from '@/assets/images/landingPage/enterprise/enterpriseSuitabilityImg.svg';
import { StyledPurpleTitle } from '@/shared/styles';
import React from 'react';
import { TextAndBanner } from '../../../Common/TextAndBanner/TextAndBanner';
import { StyledEnterpriseSuitability } from './EnterpriseSuitability.styles';
import { useTranslation } from 'react-i18next';

export const EnterpriseSuitability: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledEnterpriseSuitability>
      <TextAndBanner
        title={
          <StyledPurpleTitle $size={32}>
            {t('landing_page.enterprise.suitability.title')}
          </StyledPurpleTitle>
        }
        leftText={t('landing_page.enterprise.suitability.leftText')}
        rightText={t('landing_page.enterprise.suitability.rightText')}
        bannerImg={enterpriseSuitabilityImg}
        imgAlt={t('landing_page.enterprise.suitability.imgAlt')}
      />
    </StyledEnterpriseSuitability>
  );
};
