import { StyledAdjustableRow } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const issueDetectedImageStyle = `
    display: flex;
    width: 35%;
    height: 20%;
    margin: 16px 0px 0px 16px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    ${
      isMobile &&
      `
        position: absolute;
        bottom: 25% !important; 
        right: 0; 
        top: unset !important; 
        z-index: 1; 
        border-right: none; 
        `
    }
`;

export const EnterpriseDesktopIntro = styled(StyledAdjustableRow)`
  padding-top: ${isMobile ? '16px' : '100px'};
  ${isMobile && ` gap: 40px;`}

  img {
    ${issueDetectedImageStyle}
  }
`;
