import { Cookies } from 'react-cookie';
import { getLocalStorage } from './storageService';
import { StorageKeys } from '@/hooks/Storage/Storage.consts';
import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';
import { COOKIE_LIMIT } from '@/shared/consts';

const cookie = new Cookies();

export const setCookie = (
  key: string,
  value: object | string,
  cookieType: CookieType,
  expiryDate?: Date
) => {
  if (isCookieAllowed(cookieType)) {
    let expires;
    if (expiryDate) {
      expires = expiryDate;
    } else {
      expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
    }
    cookie.set(key, value, {
      path: '/',
      expires,
      secure: true,
    });
  }
};

export const getCookie = (key: string) => {
  return cookie.get(key);
};

export const removeCookie = (key: string) => {
  cookie.remove(key, { path: '/' });
};

export const isCookieAllowed = (cookie: CookieType) => {
  const storedCookies = getLocalStorage(StorageKeys.ALLOWED_COOKIES);
  return storedCookies ? storedCookies[cookie] : cookie === CookieType.NECESSARY;
};

export const hasExceededCookieSize = (obj: object) => {
  const strigifiedObj = JSON.stringify(obj);
  const encodedString = encodeURIComponent(strigifiedObj);
  const byteSize = new TextEncoder().encode(encodedString).length;
  return byteSize > COOKIE_LIMIT;
};
