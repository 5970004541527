import documentationTableExample from '@/assets/images/landingPage/enterprise/documentationTableExample.svg';
import { Image } from '@/shared/components/Image/Image';
import React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { CompatibilityDescription } from './CompatibilityDescription';
import {
  StyledModernCompatibilityCol,
  StyledModernCompatibilityRow,
} from './ModernCompatibility.styles';
import { useTranslation } from 'react-i18next';

export const ModernCompatibility: React.FC = () => {
  const { t } = useTranslation();
  const alt = t('landing_page.enterprise.compatibility.imgAlt');
  return (
    <StyledLandingPageSection>
      <StyledModernCompatibilityRow $wrap={isMobile ? 'wrap' : 'nowrap'}>
        {isDesktop && <Image $src={documentationTableExample} $alt={alt} />}
        <StyledModernCompatibilityCol>
          {isMobile && <Image $src={documentationTableExample} $width="100%" $alt={alt} />}
          <CompatibilityDescription />
        </StyledModernCompatibilityCol>
      </StyledModernCompatibilityRow>
    </StyledLandingPageSection>
  );
};
