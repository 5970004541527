import ourClientsImg from '@/assets/images/landingPage/enterprise/ourClientsImg.png';
import ourClientsImgWhite from '@/assets/images/landingPage/enterprise/ourClientsImgWhite.png';
import { Image } from '@/shared/components/Image/Image';
import { StyledAdjustableRow, StyledCol, StyledPurpleTitle } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { StyledLandingPageSection, StyledLPText } from '../../../LandingPages.styles';
import { useTranslation } from 'react-i18next';

export const OurClients: React.FC = () => {
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);
  const textAlign = isDesktop ? 'start' : 'center';
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <StyledAdjustableRow $gap={40}>
        <StyledCol $gap={16} $justify="center">
          <StyledPurpleTitle $align={isDesktop ? 'right' : 'center'}>
            {t('landing_page.enterprise.clients.title')}
          </StyledPurpleTitle>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.enterprise.clients.content1')}
          </StyledLPText>
          <StyledLPText textAlign={textAlign}>
            {t('landing_page.enterprise.clients.content2')}
          </StyledLPText>
        </StyledCol>
        <Image $src={isDarkTheme ? ourClientsImg : ourClientsImgWhite} $width="100%" />
      </StyledAdjustableRow>
    </StyledLandingPageSection>
  );
};
