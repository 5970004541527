import React, { useState } from 'react';
import { StyledAccordionList } from './Accordion.styles';
import { AccordionItem } from './AccordionItem';
import { U1Accordion } from '@u1/react-a11y-hooks';
import { AccordionProps } from './Accordion.consts';

export const Accordion: React.FC<AccordionProps> = ({ accrodionRows }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <U1Accordion data-u1-accordion>
      <StyledAccordionList>
        {accrodionRows.map((item, index) => (
          <AccordionItem
            key={index}
            item={item}
            showDescription={index === activeIndex}
            onClick={() => {
              setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
            }}
          />
        ))}
      </StyledAccordionList>
    </U1Accordion>
  );
};
