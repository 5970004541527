import { CACHE_TTL_SECONDS } from '@/shared/consts';
import { getLocalStorage, removeItemFromLocalStorage, setLocalStorage } from './storageService';
import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';

export const putToCache = (
  key: string,
  value: unknown,
  ttlInSeconds: number = CACHE_TTL_SECONDS.DEFAULT
) => {
  const now = Date.now();
  const ttlInMilliseconds = ttlInSeconds * 1000;

  const item = {
    value: value,
    expiry: now + ttlInMilliseconds,
  };

  setLocalStorage(key, item, CookieType.FUNCTIONAL);
};

export const getFromCache = <T>(key: string): T | null => {
  const item = getLocalStorage(key);

  if (!item) {
    return null;
  }

  try {
    const now = Date.now();

    if (now > item.expiry) {
      removeItemFromLocalStorage(key);
      return null;
    }

    return item.value as T;
  } catch (e) {
    console.error('Failed to parse localStorage item:', e);
    removeItemFromLocalStorage(key);
    return null;
  }
};
