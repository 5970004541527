import { useLocalStorage } from '../Storage/useLocalStorage';
import { StorageKeys } from '../Storage/Storage.consts';
import { AllowedCookies, CookieType } from './AllowCookies.consts';

export const useAllowCookies = () => {
  const { setStorage, getStorage } = useLocalStorage();
  const allCookiesArray = [...Object.values(CookieType)];
  const storedCookies = getStorage(StorageKeys.ALLOWED_COOKIES);
  const isCookieSaved = Boolean(storedCookies);
  const defaultCookies = {
    necessary: true,
    performance: true,
    functional: true,
    targeting: true,
    social: true,
  };

  const setAllCookies = (value: boolean) => {
    const updatedCookies = { ...defaultCookies };
    for (const key of Object.values(CookieType)) {
      updatedCookies[key as keyof AllowedCookies] = value;
    }
    updatedCookies[CookieType.NECESSARY] = true;
    setStorage(StorageKeys.ALLOWED_COOKIES, updatedCookies, CookieType.NECESSARY);
  };

  const acceptAllCookies = () => {
    setAllCookies(true);
  };

  const rejectAllCookies = () => {
    setAllCookies(false);
  };

  const savePreferences = (selectedCookies: CookieType[]) => {
    const updatedCookies = { ...defaultCookies };
    for (const key of Object.values(CookieType)) {
      updatedCookies[key as keyof AllowedCookies] = selectedCookies.includes(key);
    }
    setStorage(StorageKeys.ALLOWED_COOKIES, updatedCookies, CookieType.NECESSARY);
  };

  const isCookieAllowed = (cookie: CookieType) => {
    return storedCookies ? storedCookies[cookie] : cookie === CookieType.NECESSARY;
  };

  return {
    acceptAllCookies,
    rejectAllCookies,
    savePreferences,
    isCookieAllowed,
    allCookiesArray,
    isCookieSaved,
  };
};
