import { useLocalStorage } from '@/hooks/Storage/useLocalStorage';
import { useSubscription } from '@/hooks/useSubscription';
import { Loading } from '@/shared/components/Loading/Loading';
import { PAGES } from '@/shared/consts';
import { useSelectionStore } from '@/stores/SelectionStore';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useEffect, useState } from 'react';
import { PricingPage } from './PricingPage';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { StorageKeys } from '@/hooks/Storage/Storage.consts';

export const AfterLoginPricing: React.FC = () => {
  const { subscriptionLoading } = useSubscription();
  const subscription = useSubscriptionStore();
  const setPackage = useSelectionStore((state) => state.selectPackage);
  const { getStorage } = useLocalStorage();
  const [pricingView, setPricingView] = useState(<></>);
  const { navigate } = useU1Navigation();

  useEffect(() => {
    if (subscriptionLoading) {
      setPricingView(<Loading />);
    } else if (
      subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ||
      subscription.subscriptionStatus === SUB_STATUS.NO_SUBSCRIPTION ||
      !subscription.isActive ||
      subscription.isFreemium
    ) {
      const preSelectedPackage = getStorage(StorageKeys.SELECTED_PACKAGE);
      if (preSelectedPackage) {
        setPackage && setPackage(preSelectedPackage);
        navigate(PAGES.AFTER_LOGIN.CHECKOUT);
      } else {
        setPricingView(<PricingPage />);
      }
    }
  }, [subscription, subscriptionLoading]);

  return pricingView;
};
