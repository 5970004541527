import { StyledRowOffset } from '@/shared/styles';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';

export const StyledEAAIntro = styled(StyledRowOffset)`
  gap: 50px;
  padding: 80px 0px;

  ${(isTablet || isMobile) &&
  `
    flex-wrap: wrap;
    justify-content: center;
    `}
`;
