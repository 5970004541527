import React from 'react';
import { BookADemo } from './Areas/BookADemo/BookADemo';
import { EnterpriseSuitability } from './Areas/EnterpriseSuitability/EnterpriseSuitability';
import { Intro } from './Areas/Intro/Intro';
import { LongTerm } from './Areas/LongTerm/LongTerm';
import { ModernCompatibility } from './Areas/ModernCompatibility/ModernCompatibility';
import { Organizations } from './Areas/Organizations/Organizations';
import { OurClients } from './Areas/OurClients/OurClients';
import { StyledEnterpriseLandingPage } from './EnterpriseLandingPage.styles';

export const EnterpriseLandingPage: React.FC = () => {
  return (
    <StyledEnterpriseLandingPage>
      <Intro />
      <EnterpriseSuitability />
      <ModernCompatibility />
      <OurClients />
      <Organizations />
      <LongTerm />
      <BookADemo />
    </StyledEnterpriseLandingPage>
  );
};
