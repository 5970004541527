import { Image } from '@/shared/components/Image/Image';
import introImage from '@/assets/images/landingPage/introImg.png';
import { Intro } from './Intro';
import { StyledDesktopIntro, StyledIntroImg } from './Intro.styles';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { StyledLandingPageSection } from '../../../LandingPages.styles';

export const DesktopIntro: React.FC = () => {
  const isRtl = useLangStore((state) => state.isRtl());
  return (
    <StyledLandingPageSection>
      <StyledDesktopIntro $justify={isRtl ? 'flex-end' : 'space-between'}>
        <Intro />
        <StyledIntroImg src={introImage} />
      </StyledDesktopIntro>
    </StyledLandingPageSection>
  );
};
