import React from 'react';
import { StyledTrustedCompaniesGrid } from './Trusted.styles';
import { Icon } from '@/shared/components/Icon';
import { getTrustedCompaneis } from './Trusted.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { THEME_ICONS } from '@/shared/consts';

export const LogosGrid: React.FC = () => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const themeIcons = isDarkMode ? THEME_ICONS.WHITE : THEME_ICONS.DARK;

  return (
    <StyledTrustedCompaniesGrid>
      {getTrustedCompaneis(themeIcons).map((comp) => (
        <Icon key={comp.src} $src={comp.src} $alt={comp.alt} $size={100} $loading="lazy" />
      ))}
    </StyledTrustedCompaniesGrid>
  );
};
