import { StyledTitle } from '@/shared/styles';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';

export const ScheduleDemo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={darkTheme}>
      <StyledLandingPageSection $paddingBottom={isMobile ? 80 : 140}>
        <ButtonBanner
          $title1={
            <StyledTitle $size={28} $align="center">
              {t('landing_page.eaa.schedule_demo.title')}
            </StyledTitle>
          }
          $content={t('landing_page.eaa.schedule_demo.content')}
          $button={
            <DemoModal
              $btnText={t('landing_page.eaa.schedule_demo.btn')}
              $refName="contact-us-demo"
            />
          }
          $filled={true}
        />
      </StyledLandingPageSection>
    </ThemeProvider>
  );
};
