import { StyledPurpleTitle } from '@/shared/styles';
import a11yImportanceImg from '@/assets/images/landingPage/a11yImportanceImg.png';
import { Image } from '@/shared/components/Image/Image';
import { ImportanceDescription } from './ImportanceDescription';
import { isDesktop, isMobile } from 'react-device-detect';
import { StyledImportance, StyledImportanceCol } from './Importance.styles';
import { useTranslation } from 'react-i18next';
import { StyledLandingPageSection } from '../../../LandingPages.styles';

export const Importance: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <StyledImportance>
        <StyledImportanceCol>
          <StyledPurpleTitle level={2}>{t('landing_page.importance.title')}</StyledPurpleTitle>
          {isMobile && <Image $src={a11yImportanceImg} />}
          <ImportanceDescription />
        </StyledImportanceCol>
        {isDesktop && <Image $src={a11yImportanceImg} $width="100%" $height="100%" $flex="1" />}
      </StyledImportance>
    </StyledLandingPageSection>
  );
};
