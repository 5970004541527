import { LangProps } from '@/shared/props';
import { StyledAdjustableRow } from '@/shared/styles';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

export const StyledAbsoluteImg = styled.img<LangProps>`
  position: absolute;
  bottom: 0px;
  top: unset !important;
  width: 14rem;

  ${isMobileOnly &&
  `    
    left: 50% !important;
    transform: translateX(-50%);`}

  ${(props) =>
    props.$isRtl
      ? `
      right: unset !important;
      left: 24px !important;
    `
      : `right: 24px !important;`}
`;

export const StyledComplianceAndExperience = styled(StyledAdjustableRow)`
  justify-content: space-between;
  gap: 20px;
  padding-bottom: ${isMobile ? '80' : '140'}px;
  flex-wrap: wrap;
`;

export const StyledComplianceImg = styled.img`
  width: ${isMobileOnly ? '100' : '45'}%;
  align-self: center;
`;
