import { StyledTabletRow } from '@/shared/styles';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled, { css } from 'styled-components';

const InfoCardCss = css`
  background-color: ${(props) => props.theme.sameColorBox};
  position: relative;
  padding: 24px;
  align-items: flex-start;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.lpCardBorder};
  flex: 1 1 0;
`;

export const StyledBorderedCard = styled.div`
  ${InfoCardCss}
  display: flex;
  flex-direction: ${isMobileOnly ? 'column' : 'row'};
  justify-content: space-between;
  gap: 16px;
  min-height: ${isMobileOnly ? '600' : '400'}px;
  width: 100%;
  min-width: ${isMobile ? '284' : '504'}px;
`;

export const StyledElongatedCard = styled(StyledTabletRow)`
  ${InfoCardCss}
  gap: 24px;
  justify-content: space-between;

  img {
    max-width: ${isMobile ? '295px' : '18rem'};
    align-self: center;
  }
`;
