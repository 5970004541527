import {
  getLocalStorage,
  removeItemFromLocalStorage,
  setLocalStorage,
} from '@/services/storageService';
import { CookieType } from '../AllowCookies/AllowCookies.consts';

export const useLocalStorage = () => {
  const setStorage = (key: string, value: object | string, cookieType: CookieType) => {
    setLocalStorage(key, value, cookieType);
  };

  const getStorage = (key: string) => {
    return getLocalStorage(key);
  };

  const removeStorage = (key: string) => {
    removeItemFromLocalStorage(key);
  };

  return { setStorage, getStorage, removeStorage };
};
