import { StyledCol, StyledPurpleTitle, StyledTitle } from '@/shared/styles';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { Image } from '@/shared/components/Image/Image';
import introImg from '@/assets/images/landingPage/eaa/IntroStarsImg.png';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { StyledEAAIntro } from './Intro.styles';
import { useTranslation } from 'react-i18next';
import { isDesktop, isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';
import { eaaBgGradients } from '../../../LandingPages.consts';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  const titleAlignment = isDesktop ? 'left' : 'center';
  return (
    <ThemeProvider theme={darkTheme}>
      <StyledLandingPageSection $bgColor={eaaBgGradients.intro}>
        <StyledEAAIntro>
          <StyledCol $gap={32} $justify="center" $width="610px">
            <StyledCol $gap={8}>
              <StyledPurpleTitle $size={20} $align={titleAlignment}>
                {t('landing_page.eaa.intro.title')}
              </StyledPurpleTitle>
              <StyledTitle level={2} $size={32} $align={titleAlignment}>
                {t('landing_page.eaa.intro.content')}
              </StyledTitle>
            </StyledCol>
            <DemoModal
              $btnText={t('landing_page.eaa.book_demo')}
              $btnWidth={isDesktop ? 'fit-content' : '100%'}
            />
          </StyledCol>

          <Image $src={introImg} $width={isMobile ? '100%' : '40%'} />
        </StyledEAAIntro>
      </StyledLandingPageSection>
    </ThemeProvider>
  );
};
