import { StyledCol } from '@/shared/styles';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledOurSolution = styled(StyledCol)`
  border: 1px solid ${(props) => props.theme.landingPageBorder};
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  min-height: ${isMobile ? '1200' : '748'}px;
`;

export const StyledRightColImg = styled.img`
  position: relative;
  right: -40px !important;
  bottom: -40px !important;
  top: unset !important;
  flex: 1;
  align-self: flex-end;
`;

export const StyledLeftCol = styled(StyledCol)`
  gap: 32px;
  flex: 1;
  min-width: 253px;
`;
