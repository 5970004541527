import { TextProps } from '@/shared/props';
import { StyledTitle } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

const GetStartedCss = css`
  font-size: ${isMobile ? 24 : 28}px;
  font-weight: 600;
`;

export const StyledGetStartedPurpleTitle = styled(StyledTitle)`
  ${GetStartedCss}
  color: ${(props) => props.theme.lightWhiteDarkPurpleText} !important;
`;

export const StyledGetStartedPurpleText = styled.span<TextProps>`
  ${GetStartedCss}
  color: ${(props) => props.theme.lightWhiteDarkPurpleText} !important;
  word-break: keep-all;
`;

export const StyledGetStartedWhiteText = styled.span`
  ${GetStartedCss}
  color: ${(props) => props.theme.sameColorText} !important;
`;
