import React from 'react';
import { StyledFooterCol, StyledFooterContent } from './Footer.styles';
import { Icon } from '@/shared/components/Icon';
import { StyledCol, StyledRow } from '@/shared/styles';
import { u1Logo } from '@/assets/images/landingPage/icons';
import { U1Link } from '@/shared/components/Link/U1Link';
import { socialMedia } from './Footer.consts';
import { useTranslation } from 'react-i18next';

export const User1stDetails: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFooterCol $maxWidth="190px">
      <Icon $maxWidth={190} $src={u1Logo} $alt={t('landing_page.logo')} />
      <StyledCol $gap={16}>
        <U1Link href="mailto:support@user1st.com" text="support@user1st.com" />
        <StyledFooterContent>{t('landing_page.footer.address')}</StyledFooterContent>
      </StyledCol>
      <StyledRow $gap={20}>
        {socialMedia.map((social) => (
          <a
            aria-label={social.name}
            href={social.href}
            key={social.name}
            target="_blank"
            rel="noreferrer"
          >
            <Icon $src={social.icon} $alt="" $loading="lazy" />
          </a>
        ))}
      </StyledRow>
    </StyledFooterCol>
  );
};
