import { U1Button } from '@/shared/components/Button/U1Button';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { isDesktop, isTablet } from 'react-device-detect';
import { DemoModalProps } from './DemoModal.consts';

export const DemoModal: React.FC<DemoModalProps> = ({
  $btnText,
  $refName,
  $btnWidth,
}: DemoModalProps) => {
  return (
    <ContactUsModal
      refName={`${$refName ?? `contact-us-intro`}`}
      trigger={
        <U1Button
          text={$btnText}
          $width={$btnWidth ?? (isDesktop || isTablet ? 'fit-content' : '100%')}
          $padding="8px 24px"
        />
      }
    />
  );
};
