import { StyledAdjustableRow, StyledCard, StyledCol } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { StyledLPText } from '../../../LandingPages.styles';

export const StyledSolutionsCard = styled(StyledCard)`
  height: 90px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  background-color: ${(props) => props.theme.overlayTextBG};

  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
`;

export const StyledSolutionsCardText = styled(StyledLPText)`
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledModernCompatibilityRow = styled(StyledAdjustableRow)`
  gap: 40px;
  align-items: ${isMobile ? 'center' : 'flex-start'};
  justify-content: center;

  img {
    border: 2px solid ${(props) => props.theme.overlayTextBorder};
    border-radius: 24px;
  }
`;

export const StyledModernCompatibilityCol = styled(StyledCol)`
  gap: 16px;
  ${isMobile &&
  `
    align-items: center;
  `}
`;
