import { Switch } from 'antd';
import styled from 'styled-components';
import { darkModeIcon } from '@/assets/images/icons';

export const StyledThemeSwitcher = styled(Switch)`
  background: ${(props) => props.theme.buttonsBG};
  min-width: 29px;

  &.ant-switch-checked .ant-switch-handle::before {
    background: url(\"${darkModeIcon}\");
    background-size: contain;
  }

  .ant-switch-handle {
    width: 12px;
    height: 12px;
    top: 4px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 15px - 2px);
  }
`;
