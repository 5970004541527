import { TextProps, TitleProps } from '@/shared/props';
import { StyledAdjustableRow, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { LandingPageProps, LandingPageSectionProps } from './LandingPages.consts';
import Title from 'antd/lib/typography/Title';

export const StyledWhiteTitle = styled(Title)<TitleProps>`
  color: ${(props) => props.theme.lpText} !important;
  font-weight: ${(props) => props.$weight ?? '600'};
  font-size: ${(props) => props.$size ?? '32px'};
  align-self: ${(props) => (props.$flex ? `${props.$flex}` : `center`)} !important;
  word-break: keep-all;
`;

export const StyledLandingPage = styled.main<LandingPageProps>`
  margin-bottom: ${isMobile ? '40px ' : '100px'};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.$allowGaps && `gap: ${isMobile ? '80' : '120'}px;`}

  img {
    top: 0px;
    right: 0px;
  }
`;

export const StyledFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${isMobile ? '1' : '3'}, 1fr);
  gap: ${isMobile ? '2' : '1'}rem;
  justify-items: center;
`;

export const StyledHybridTitle = styled(StyledTitle)<{ $break?: boolean }>`
  font-size: ${(props) => props.$size ?? '44'}px !important;
  font-weight: 600 !important;
  width: fit-content;
  gap: 8px;
  ${(props) => props.$align && `align-items: ${props.$align};`}
  ${(props) =>
    props.$break &&
    `
    display: flex;
    flex-direction: column;
    `}
`;

export const StyledLPText = styled.div<TextProps & { $width?: string }>`
  font-size: ${(props) => props.fontSize ?? '16px'};
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
`;

export const StyledLandingPageFooter = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 60px 25px;
  gap: 40px;
  width: 100%;
  background-color: ${(props) => props.theme.sameColorBG};
`;

export const StyledMobileAdaptiveRow = styled(StyledAdjustableRow)`
  ${isMobile && `gap: 60px;`}
`;

export const StyledLandingPageSection = styled.div<LandingPageSectionProps>`
  padding-right: ${(props) => props.$paddingRight ?? (isMobile ? '16px' : '100px')};
  padding-left: ${(props) => props.$paddingLeft ?? (isMobile ? '16px' : '100px')};

  ${(props) =>
    props.$bgColor &&
    `
   background: ${props.$bgColor};
  `};

  ${(props) =>
    props.$bgImg &&
    `
   background-image: url(${props.$bgImg});
   background-repeat: no-repeat; 
   background-position: center;
   background-size: 100% 100%;
  `};

  ${(props) => props.$paddingTop && `padding-top: ${props.$paddingTop}px;`}
  ${(props) => props.$paddingBottom && `padding-bottom: ${props.$paddingBottom}px;`}
`;
