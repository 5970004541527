import { StyledCol, StyledTitle } from '@/shared/styles';
import { InfoCardProps } from '../Cards.consts';
import { StyledElongatedCard } from '../Cards.styles';
import { StyledLPText } from '../../../LandingPages.styles';
import { Image } from '@/shared/components/Image/Image';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';

export const ElongatedInfoCard: React.FC<InfoCardProps> = ({
  $title,
  $content,
  $img,
}: InfoCardProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <StyledElongatedCard>
        <StyledCol $gap={16}>
          <StyledTitle level={2} $size={18}>
            {$title}
          </StyledTitle>
          <StyledLPText fontSize="18px">{$content}</StyledLPText>
        </StyledCol>
        <Image $src={$img as string} />
      </StyledElongatedCard>
    </ThemeProvider>
  );
};
