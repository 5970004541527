import { StyledRow } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledTrustedCompaniesGrid = styled(StyledRow)`
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;

  img {
    max-width: ${isMobile ? '70' : '100'}px;
    object-fit: contain;
  }
`;
