﻿import { AccordionApi, AccordionOptions, AccordionProps } from '@u1/fixers';
import { useU1Base } from './base';
import { BaseOptions } from '../shared/types';
import { allowedOptionsKeys } from './consts';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1Accordion = (baseOptions?: BaseOptions<AccordionProps, AccordionOptions>) => {
  return useU1Base(
    AccordionApi,
    true,
    allowedOptionsKeys.accordion,
    ComponentName.ACCORDION,
    baseOptions
  );
};
