import { DomainIcon, FrameworkIcon, UserIcon } from '@/assets/images/icons';
import { U1Button } from '@/shared/components/Button/U1Button';
import { Icon } from '@/shared/components/Icon';
import { StyledCol, StyledRow, StyledSeparator } from '@/shared/styles';
import { PackgeDetailsProps } from '../../Pricing.consts';
import {
  StyledDetailsList,
  StyledFrameWorks,
  StyledFreeTrial,
  StyledIcons,
} from '../Package.styles';
import { RowDetail } from '../PackageDetails/RowDetail';
import { useTranslation } from 'react-i18next';

export const MainPackageDetails: React.FC<PackgeDetailsProps> = ({
  users,
  frameworks,
  supportedFrameworks,
  domains,
  choosePackage,
}: PackgeDetailsProps) => {
  const { t } = useTranslation();
  return (
    <StyledCol $justify="space-between" $height="100%">
      <StyledSeparator />

      <StyledIcons>
        <RowDetail $icon={UserIcon} $size={20} details={users.quantityTitle} />

        <RowDetail $icon={FrameworkIcon} $size={20} details={frameworks.quantityTitle} />

        <StyledRow role="presentation">
          <StyledFrameWorks>
            {supportedFrameworks?.map((fw) => (
              <Icon $src={fw.icon} key={fw.name} $size={17} $alt={fw.title} />
            ))}
          </StyledFrameWorks>
        </StyledRow>

        <RowDetail $icon={DomainIcon} $size={20} details={domains.quantityTitle} />
      </StyledIcons>

      <StyledFreeTrial>
        <h4>{t('pricing.package.freeTrial')}</h4>
      </StyledFreeTrial>
      <StyledDetailsList>
        <li>{t('pricing.package.listitem1')}</li>
        <li>{t('pricing.package.listitem2')}</li>
        <li>{t('pricing.package.listitem3')}</li>
      </StyledDetailsList>

      <U1Button
        dataTestId="selectPackageButton"
        text={t('pricing.package.select')}
        $height={'40px'}
        onClick={choosePackage}
        isLink={true}
      />
    </StyledCol>
  );
};
