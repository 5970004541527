import { StyledCol } from '@/shared/styles';
import {
  StyledFeaturesContainer,
  StyledLandingPageSection,
  StyledWhiteTitle,
} from '../../LandingPages.styles';
import { Icon } from '@/shared/components/Icon';
import { StyledFeatureCard, StyledFeatureText, StyledFeatureTitle } from './Features.styles';
import { useTranslation } from 'react-i18next';
import { featuresImages } from './Features.consts';
import { TitleColorProps } from '../../LandingPages.consts';

export const OurFeatures: React.FC<TitleColorProps> = (props: TitleColorProps) => {
  const { t } = useTranslation();
  const { $titleAlign, ...restOfProps } = props;
  return (
    <StyledLandingPageSection {...restOfProps}>
      <StyledCol $gap={40}>
        <StyledWhiteTitle level={2} $align={$titleAlign ?? 'left'}>
          {t('landing_page.features.title')}:
        </StyledWhiteTitle>
        <StyledFeaturesContainer>
          {featuresImages.map((img, i) => (
            <StyledFeatureCard key={`features${i}`}>
              <Icon $src={img} $size={32} />
              <StyledCol $gap={8}>
                <StyledFeatureTitle level={3}>
                  {t(`landing_page.features.block${i + 1}.title`)}:
                </StyledFeatureTitle>
                <StyledFeatureText>
                  {t(`landing_page.features.block${i + 1}.content`)}
                </StyledFeatureText>
              </StyledCol>
            </StyledFeatureCard>
          ))}
        </StyledFeaturesContainer>
      </StyledCol>
    </StyledLandingPageSection>
  );
};
