import { SwitchButtonProps } from './ToggleButton.consts';
import { StyledSwitch } from './ToggleButton.styles';

export const ToggleButton: React.FC<SwitchButtonProps> = ({
  onSwitch,
  label,
  isChecked,
}: SwitchButtonProps) => {
  return (
    <StyledSwitch
      defaultChecked={isChecked !== undefined ? isChecked : true}
      onChange={onSwitch}
      aria-label={label}
      data-u1-button="button"
    />
  );
};
