import { useTranslation } from 'react-i18next';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { StyledBookADemoTitle } from './BookADemo.styles';

export const BookADemo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection $paddingBottom={140}>
      <ButtonBanner
        $title1={
          <StyledBookADemoTitle $size={28}>
            {t('landing_page.enterprise.book_demo.title')}
          </StyledBookADemoTitle>
        }
        $content={t('landing_page.enterprise.book_demo.content')}
        $button={
          <DemoModal
            $btnText={t('landing_page.enterprise.book_demo.btn')}
            $refName="contact-us-demo"
          />
        }
      />
    </StyledLandingPageSection>
  );
};
