export interface UserJourney {
  [key: string]: string;
}

// Standard UTM Parameters
export const utmParams = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];

export const journeyParams = [
  // Default params
  'default_campaign',
  'default_medium',
  'default_source',
  'default_term',
  'default_content',

  // Google Ad Parameters
  'gclid',
  'dclid',
  'matchtype',
  'device',
  'placement',
  'network',
  'adgroupid',
  'campaignid',
  'targetid',
  'creative',
  // Meta (Facebook and Instagram) Ad Parameters
  'fbclid',
  'ad_id',
  'adset_id',
  'campaign_id',
  // TikTok Tracking Parameters
  'ttclid',
  'adgroup_id',
  // LinkedIn and Twitter Parameters
  'lnclid',
  'twclid',
  'creative_id',
  // Quantcast and Converge (cq_) Parameters
  'cq_src',
  'cq_cmp',
  'cq_con',
  'cq_term',
  'cq_net',
  'cq_plt',
  // Other Tracking Parameters
  'obclid', // Outbrain
  'tbclid', // Taboola
  'msclkid', // Microsoft (Bing) Ads
  'created_at',
];
