export const COLORS = {
  DARK_GRAY: '#212121',
  MEDIUM_GRAY: '#272727',
  LIGHT_GRAY: '#B6A7D1',
  PURPLE_GRAY: '#352F40',
  LIGHT_PURPLE_GRAY: '#574E68',
  PURPLE: '#7914C5',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  DUSTY_PINK: '#E6A0F0',
  BLACK_OPACITY: 'rgba(0, 0, 0, 0.3)',
  PINK_TO_WHITE_GRADIENT:
    'linear-gradient(90deg,rgba(193,148,218,1) 2%,rgba(197,150,221,1) 5%,rgba(201,151,223,1) 10%,rgba(205,152,226,1) 12%,rgba(209,154,228,1) 15%,rgba(213,155,231,1) 38%,rgba(217,156,233,1) 43%,rgba(221,157,235,1) 50%,rgba(230,160,240,1) 62%,rgba(234,161,243,1) 68%,rgba(238,163,245,1) 75%,rgba(242,164,248,1) 81%,rgba(246,165,250,1) 87%,rgba(250,167,253,1) 93%,rgba(254,168,255,1) 100%)',
  PURPLE_GRADIENT: 'linear-gradient(180deg, #4A00CB 0%, #7914C5 100%)',
  PURPLE_GRADIENT_HORIZONTAL: 'linear-gradient(90deg, #4A00CB 0%, #7914C5 100%)',
  TWO_COLOR_PURPLE_GRADIENT:
    'linear-gradient(180deg, rgba(74, 0, 203, 0.3) 0%, rgba(121, 20, 197, 0.3) 100%)',
  YELLOW_ORANGE_GRADIENT:
    'linear-gradient(90deg, rgba(253, 231, 76, 1) 0%, rgba(248, 208, 79, 1) 6.25%, rgba(244, 188, 81, 1) 12.5%, rgba(241, 171, 83, 1) 18.75%, rgba(238, 156, 85, 1) 25%, rgba(235, 143, 86, 1) 31.25%, rgba(233, 133, 87, 1) 37.5%, rgba(232, 124, 88, 1) 43.75%, rgba(230, 117, 89, 1) 50%, rgba(229, 112, 90, 1) 56.25%, rgba(228, 108, 90, 1) 62.5%, rgba(228, 105, 91, 1) 68.75%, rgba(227, 103, 91, 1) 75%, rgba(227, 102, 91, 1) 81.25%, rgba(227, 101, 91, 1) 87.5%, rgba(227, 101, 91, 1) 93.75%, rgba(227, 101, 91, 1) 100%)',
  PURPLE_WHITE_GRADIENT: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(119,48,242,1) 85%);`,
  LIGHT_DARK_PURPLE_GRADIENT: `linear-gradient(90deg, rgba(119,48,242,1) 7%, rgba(159,55,237,1) 100%);`,
  PALE_RED: '#CC4545',
  WHITE_TO_PINK_GRADIENT:
    'linear-gradient(90deg, #DBC3E9 0%, #DDBEEA 6.25%, #DFB9EC 12.5%, #E2B4ED 16%, #E4AFEF 18%, #E6AAF0 31.25%, #E8A5F1 37.5%, #EAA0F3 43.75%, #ED9BF4 50%, #EF96F5 56.25%, #F191F7 62.5%, #F38CF8 68.75%, #F587FA 75%, #F782FB 81.25%, #FA7DFC 87.5%, #FC78FE 93.75%, #FE73FF 100%);',
  GREEN_GRADIENT:
    'var(--gradients-additional, linear-gradient(90deg, #9BF3F0 0%, #9EF5DF 6.25%, #A1F6D1 12.5%, #A3F7C4 18.75%, #A5F8BA 25%, #A7F9B1 31.25%, #A9FAA9 37.5%, #AAFAA3 43.75%, #ABFB9E 50%, #ABFB9A 56.25%, #ACFC97 62.5%, #ACFC95 68.75%, #ADFC93 75%, #ADFC93 81.25%, #ADFC92 87.5%, #ADFC92 93.75%, #ADFC92 100%));',
  EXTRA_DARK_GRAY: '#1E1E1E',
  SUPER_DARK_GRAY: '#0E0E0E',
  LIGHT_GRAY_OPACITY: 'rgba(122, 122, 122, 0.12)',
  DEEP_PURPLE: `#6E657D`,
  DUSTY_RED: '#CC454526',
  DARK_PURPLE_GRAY: '#2D2836',
  GREEN: '#33C211',
  DARK_PURPLE_GRADIENT:
    '#352f40 -webkit-gradient(linear, 50% 0, 0 0, from(#352f40), color-stop(0.5, #3b334a), to(#352f40))',
  GRAY_PURPLE: '#564C66',
  DARK_PURPLE: '#2A282E',
  MEDIUM_YELLOW: '#fdd835',
  REDDISH_PINK: '#E46C5A',
  PURPLE_OPACITY: '#7914c50e',
  SIMPLE_PURPLE_GRAD: 'linear-gradient(180deg, #4A00CB 0%, #7914C5 100%)',
  SHINY_PURPLE: '#7730F2',
  DARK_BLUE_MAGENTA: '#3C344B',
  YELLOW_ORANGE_LIGHT_GRADIENT:
    'linear-gradient(90deg, #FBAB0E 0%, #F9A713 6.25%, #F8A218 12.5%, #F69E1C 18.75%, #F59921 25%, #F39526 31.25%, #F2912B 37.5%, #F08C30 43.75%, #EF8835 50%, #ED8439 56.25%, #EC7F3E 62.5%, #EA7B43 68.75%, #E97748 75%, #E7724D 81.25%, #E66E51 87.5%, #E46956 93.75%, #E3655B 100%)',
  GRAYISH_PURPLE: '#635874',
  GHOST_WHITE: '#F9FAFB',
  BRIGHT_GRAY: '#EAEAF0 ',
  LIGHT_GRAYISH_VIOLET: '#f7f5f9',
  DEEP_GRAYISH_VIOLET: '#a29bb0',
  LAVENDER_GRADIENT: 'linear-gradient(180deg, #A06EF7 0%, #BF84EB 100%)',
  EXTRA_LIGHT_GRAY_OPACITY: 'rgba(15, 14, 22, 0.06)',
  LIGHT_SILVER: '#D8D8D8',
  YELLOW_ORANGE_GRADIENT_LIGHT:
    'linear-gradient(90deg, #F8AB59 0%, #FA9713 13%, #F6921C 25%, #F39E21 38%, #F39526 50%, #ED8439 63%, #EA7743 75%, #E6654D 88%, #E3655B 100%)',
  PURPLE_TO_WHITE_GRADIENT:
    'linear-gradient(90deg, rgba(125,51,255,1) 26%, rgba(153,50,231,1) 93%)',
  SMOKEY_WHITE: '#F3F3F3',
  LIGHT_PURPLE_GRADIENT:
    ' #FFFFFF -webkit-gradient(linear, 50% 0, 0 0, from(#FFFFFF), color-stop(0.5, #E6E1F0), to(#FFFFFF))',
  OLD_LAVENDER: `#716A81`,
  DARK_SHINY_PURPLE: `#4A00CB`,
  EXTRA_LIGHT_PURPLE: `#D5C7FF`,
  TRANSPARENT: `transparent`,
  LAVENDER_BLUE: `#D5C7FF`,
  U1_BRAND_PURPLE: `#4A00CB`,
};
