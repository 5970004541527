import { AcceptCookiesAccrodion } from './CookiesAccordion';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { useAllowCookies } from '@/hooks/AllowCookies/useAllowCookies';
import { U1Button } from '@/shared/components/Button/U1Button';
import { AcceptCookiesActionsProps } from '../CookieModal.consts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { StyledButtonsContainer } from '../CookieModal.styles';

export const AcceptCookiesActions: React.FC<AcceptCookiesActionsProps> = ({
  handleOk,
}: AcceptCookiesActionsProps) => {
  const { acceptAllCookies, rejectAllCookies, savePreferences, allCookiesArray } =
    useAllowCookies();
  const [selectedCookies, setSelectedCookies] = useState(allCookiesArray);
  const { t } = useTranslation();
  const buttonWidth = isMobile ? '100%' : 'fit-content';

  return (
    <>
      <AcceptCookiesAccrodion setSelectedCookies={setSelectedCookies} />
      <StyledButtonsContainer>
        <GradientBorderButton
          text={t('cookie_banner.settings_modal.manage_consent.save')}
          $padding="14px 20px"
          $width={buttonWidth}
          onClick={() => {
            savePreferences(selectedCookies);
            handleOk();
          }}
        />
        <GradientBorderButton
          text={t('cookie_banner.settings_modal.manage_consent.reject_all')}
          $padding="14px 20px"
          $width={buttonWidth}
          onClick={() => {
            rejectAllCookies();
            handleOk();
          }}
        />
        <U1Button
          text={t('cookie_banner.settings_modal.manage_consent.accept_all')}
          $width={buttonWidth}
          onClick={() => {
            acceptAllCookies();
            handleOk();
          }}
        />
      </StyledButtonsContainer>
    </>
  );
};
