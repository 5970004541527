import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import React, { useState, useEffect } from 'react';
import { TreeItemProps } from '../../../../Client/Projects/projects.consts';
import { Icon } from '@/shared/components/Icon';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const TreeItemIcon: React.FC<TreeItemProps> = ({ compName }: TreeItemProps) => {
  const docs = useDocsStore();
  const selectedComponentDoc = useDocsStore((state) => state.selectedComponentDoc);
  const [src, setSrc] = useState(
    docs.compIcons[compName][selectedComponentDoc?.name === compName ? 'active' : 'inactive']
  );
  const isDarkMode = useThemeStore((state) => state.isDarkMode);

  useEffect(() => {
    const isSelected = selectedComponentDoc?.name === compName;
    setSrc(docs.compIcons[compName][isSelected ? 'active' : 'inactive']);
  }, [selectedComponentDoc]);

  return <Icon $src={src} $invert={!isDarkMode} />;
};
