import {
  StyledAbsoluteImg,
  StyledComplianceAndExperience,
  StyledComplianceImg,
} from './ComplianceAndExperience.styles';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import complianceImg from '@/assets/images/landingPage/eaa/compliance.png';
import experienceImg from '@/assets/images/landingPage/eaa/experience.png';
import { BorderedInfoCard } from '../../../Common/Cards/BorderedInfoCard/BorderedInfoCard';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const ComplianceAndExperience: React.FC = () => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());

  return (
    <StyledLandingPageSection>
      <StyledComplianceAndExperience>
        <BorderedInfoCard
          $title={t('landing_page.eaa.compliance.title')}
          $content={t('landing_page.eaa.compliance.content')}
          $img={<StyledComplianceImg src={complianceImg} alt="" />}
        />
        <BorderedInfoCard
          $title={t('landing_page.eaa.experience.title')}
          $content={t('landing_page.eaa.experience.content')}
          $img={<StyledAbsoluteImg src={experienceImg} $isRtl={isRtl} alt="" />}
        />
      </StyledComplianceAndExperience>
    </StyledLandingPageSection>
  );
};
