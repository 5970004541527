import { useEffect } from 'react';
import { Accordion } from '../../../Accordion/Accordion';
import { ToggleButton } from '../../../ToggleButton/ToggleButton';
import { StyledPinkGradText } from '../CookieModal.styles';
import { AcceptCookiesAccrodionProps } from '../CookieModal.consts';
import { CookieType } from '@/hooks/AllowCookies/AllowCookies.consts';
import { useAllowCookies } from '@/hooks/AllowCookies/useAllowCookies';
import { t } from 'i18next';

export const AcceptCookiesAccrodion: React.FC<AcceptCookiesAccrodionProps> = ({
  setSelectedCookies,
}: AcceptCookiesAccrodionProps) => {
  const { allCookiesArray } = useAllowCookies();
  const optionalCookies = [
    CookieType.FUNCTIONAL,
    CookieType.PERFORMANCE,
    CookieType.TARGETING,
    CookieType.SOCIAL,
  ];

  useEffect(() => {
    setSelectedCookies(allCookiesArray);
  }, []);

  const setCookie = (cookieName: CookieType, isSelected: boolean) => {
    if (isSelected) {
      setSelectedCookies((prevCookies) => [...prevCookies, cookieName]);
    } else {
      setSelectedCookies((prevCookies) => prevCookies.filter((prev) => prev !== cookieName));
    }
  };

  const generateOptionalCookies = (cookieType: CookieType, index: number) => {
    return {
      title: t(`cookie_banner.settings_modal.manage_consent.cookie${index}.title`),
      content: t(`cookie_banner.settings_modal.manage_consent.cookie${index}.content`),
      additionalItem: (
        <ToggleButton
          label={t(`cookie_banner.settings_modal.manage_consent.cookie${index}.title`)}
          onSwitch={(isSelected: boolean) => {
            setCookie(cookieType, isSelected);
          }}
        />
      ),
    };
  };

  return (
    <Accordion
      accrodionRows={[
        {
          title: t('cookie_banner.settings_modal.manage_consent.cookie1.title'),
          content: t('cookie_banner.settings_modal.manage_consent.cookie1.content'),
          additionalItem: <StyledPinkGradText>{t('active')}</StyledPinkGradText>,
        },
        ...optionalCookies.map((cookie, i) => generateOptionalCookies(cookie, i + 2)),
      ]}
    />
  );
};
