import enterpriseLandingPageIntroImg from '@/assets/images/landingPage/enterprise/enterpriseLandingPageIntroImg.png';
import issuesDetectedPopup from '@/assets/images/landingPage/enterprise/issuesDetectedPopup.svg';
import { Image } from '@/shared/components/Image/Image';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { TextInsideImage } from '../../../Common/TextInsideImage/TextInsideImage';
import { StyledLandingPageSection } from '../../../LandingPages.styles';
import { IntroContent } from './IntroContent';
import { EnterpriseDesktopIntro } from './intro.styles';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export const Intro: React.FC = () => {
  const isRtl = useLangStore((state) => state.isRtl());
  const caption = `User1st aims to create a barrier-free digital environment by ensuring compliance with accessibility standards such as the Web Content Accessibility Guidelines (WCAG), Americans with Disabilities Act (ADA) and The European accessibility act  (EAA).
  `;
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <EnterpriseDesktopIntro $justify={isRtl ? 'flex-end' : 'space-evenly'} $align="center">
        <IntroContent />
        <TextInsideImage
          $className="issueDetectedImage"
          $width={isMobile ? '100%' : '45%'}
          $txt={t('landing_page.enterprise.intro.caption')}
          $imgSrc={enterpriseLandingPageIntroImg}
        >
          <Image $src={issuesDetectedPopup} $alt="issues detected popup example" />
        </TextInsideImage>
      </EnterpriseDesktopIntro>
    </StyledLandingPageSection>
  );
};
